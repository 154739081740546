import * as React from "react"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Footer from "../components/footer.component";
import Header from "../components/header.component";

const styles = {
  main: {
   width: '100%',
   minHeight: '100vh',
  },
  container: {
    padding: "50px 10% 50px 10%"
  } 
};

const PrivacyPage = () => {
  const breakpoints = useBreakpoint();
  
  const [displayVideoPresentation, setDisplayVideoPresentation] = React.useState(false);

  return (
      <main style={styles.main}>
        <Header title={"Politique de confidentialité des données"} />

        <section style={styles.container}>
          <b>La présente politique décrit les informations que nous traitons pour le fonctionnement et l'utilisation de Memble.</b>
        
          <h3>1. Quels types d’informations recueillons-nous ?</h3>
          <p>Pour l'utilisation et l'amélioration de l'experience nous récupérons certaines informations:</p>
          <ul>
            <li>Informations liées à votre compte utilisateur : Votre email, un nom d'utilisateur ainsi que votre âge vous seront demandés afin de vous authentifier. L'âge permet de bloquer l'accès aux utilisateurs dont l'âge est inférieur à 13 ans. Nous utilisons également cette donnée à des fins statistiques.</li>
            <li>Nous sauvegardons : vos publications, vos groupes, vos réactions, vos commentaires et les groupes que vous suivez.</li>
          </ul>
        
        
         <h3>2. Les services tiers</h3>
          <ul>
            <li>Cloudinary: Nous utilisons les services de la société cloudinary afin de stocker les images et vidéos que vous postez sur Memble</li>
            <li>Google Analytics: Le service Google Analytics afin de récupérer une analyse statistiques des comportements de nos utilisateurs (localisation, pages visitées, durées de sessions).</li>
            <li>Firebase: envoie de notifications push et analyses statistiques.</li>
          </ul>

          <h3>3. Hébergement</h3> 
          <p>Nos services sont répartis chez différents hébergeurs. Nos applications mobiles sont stockées sur le Google Play Store et l'Apple Store alors que notre API est hébergée chez <a href="https://heroku.com">Heroku</a>.</p>
          <p>Pour le stockages des contenus multimedias, nous utilisons Cloudinary.</p>

          <h3>4. Suppression de vos données</h3>
          <p>A la suppression de votre compte, la totalité de vos données (publications, groupes, commentaires, réactions) sont supprimées. Certaines statistiques sont conservée mais totalement anonymisée.</p>

          <b>Date de rédaction: 23 novembre 2021</b>
        </section>

        <Footer/>
      </main>
  )
}


export default PrivacyPage
